<template>
    <div class="citation_box">
        <div class="box">
            <div class="box_part" v-bind:style="{ minHeight: Height - 40 + 'px' }">
                <div class="box_top">
                    <div class="top_left">
                        <!-- <h2>作者基本信息</h2> -->
                        <div class="left_data">

                            <div class="tables" style="width: 700px">
                                <h2>引文指数信息</h2>
                                <h3>Citation index information</h3>
                                <div class="citation">
                                    <div style="background: #ffe2e6">
                                        <img src="../../assets/imgs/引文追踪/Icon.png" alt="" />
                                        <p class="number">{{ tableData[0].publication }}</p>
                                        <p class="type">发文量</p>
                                        <p class="addText">+8% from last month</p>
                                    </div>
                                    <div style="background: #fff4de">
                                        <img src="../../assets/imgs/引文追踪/Icon (1).png" alt="" />
                                        <p class="number">{{ tableData[0].cit }}</p>
                                        <p class="type">总被引频次</p>
                                        <p class="addText">+5% from last month</p>
                                    </div>
                                    <div style="background: #f4e8ff">
                                        <img src="../../assets/imgs/引文追踪/Icon (2).png" alt="" />
                                        <p class="number">{{ tableData[0].hNum }}</p>
                                        <p class="type">H指数</p>
                                        <p class="addText">0.5% from last month</p>
                                    </div>
                                </div>
                            </div>
                            <div class="tables" style="width: 700px">
                                <h2>近八年内发文量与被引频次对比统计</h2>
                                <h3>Compare statistics</h3>
                                <div class="Echarts">
                                    <div id="main" style="width: 700px; height: 300px"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box_list">
                    <h2>发表文献列表</h2>
                    <h3>List of published articles</h3>
                    <div class="operate">
                        <el-button type="primary" icon="el-icon-delete" @click="deletes">
                            删除</el-button>
                        <el-button type="primary" icon="el-icon-download" @click="download">导出</el-button>
                        <el-button type="primary" icon="el-icon-upload" @click="uploadOpen">导入</el-button>

                        <el-select v-model="value" placeholder="请选择" style="width: 135px; margin: 0px 20px"
                            @change="selectSort">
                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <el-table ref="multipleTable" :data="taskList" tooltip-effect="dark" style="border-radius: 16px">
                        <el-table-column type="selection" width="40" align="center">
                        </el-table-column>

                        <el-table-column prop="article_title" label="标题" align="left">
                        </el-table-column>
                        <el-table-column prop="article_year" label="发表时间" align="left" width="100">
                        </el-table-column>
                        <el-table-column label="作者" align="center">
                            <template slot-scope="scope">
                                <span v-for="(item_article, index) in scope.row.article_author" :key="index">{{
            item_article }}&ensp;;&ensp;</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="article_source_data" label="来源" align="center">
                        </el-table-column>
                        <el-table-column prop="doi" label="DOI" align="center">

                        </el-table-column>
                        <el-table-column prop="issn" label="ISSN" align="center">

                        </el-table-column>
                        <el-table-column prop="sub" label="专辑" align="center">

                        </el-table-column>

                        <el-table-column label="被引频次" align="center">
                            <template slot-scope="scope" class="stats">
                                <el-badge :value="scope.row.new_cited_frequency" class="item"
                                    v-if="scope.row.new_cited_frequency > 0">
                                    <el-tag type="success" @click="reads(scope.row)">{{
            scope.row.article_cited_frequency
        }}</el-tag>
                                </el-badge>
                                <el-tag type="success" v-if="scope.row.cit_is_new == 0">{{
            scope.row.article_cited_frequency
        }}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="详情链接" align="center">
                            <template slot-scope="scope" class="stats">
                                <el-button type="primary" size="mini" @click="detail(scope.row)">
                                    查看详情</el-button>
                            </template>
                        </el-table-column>

                        <template slot="empty">
                            <div class="imgs">
                                <img src="../../assets/imgs/zanwushuj.png" alt="" style="width: 50%" />
                            </div>
                        </template>
                    </el-table>
                    <div class="pag">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" background layout="total, prev, pager, next, jumper"
                            :total="totalCount" v-if="totalCount > 0">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="中文文献导入" :visible.sync="dialogFormVisible" :width="'500px'">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" name="file" :data="ruleForm" label-width="100px"
                class="demo-ruleForm" id="uploadForm" style="margin: 0 50px">
                <el-upload class="upload-demo" id="uploadForm" ref="upload" drag :action="actionUrl"
                    :headers="{ token: token }" :before-upload="beforeUpload" :on-preview="handlePreview"
                    :on-remove="handleRemove" :on-change="on_change" :before-remove="beforeRemove" multiple :limit="1"
                    :on-exceed="handleExceed" :auto-upload="false" :file-list="fileList" style="max-height: 180px">
                    <div class="upStyle">
                        <div>
                            <div style="">
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">
                                    <em style="">点击上传</em>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="el-upload__tip" slot="tip">只能上传txt/docx/pdf文件</div> -->
                </el-upload>
                <div class="note_fu">
                    <div class="note_fu01">
                        <el-divider content-position="left">注意事项</el-divider>
                        <div class="note">
                            <p>
                                <img src="../../assets/imgs/yellow.png" alt="" />
                                <span>所上传中文文献必须为收录在知网的文献</span>
                            </p>
                            <p>
                                <img src="../../assets/imgs/yellow.png" alt="" />
                                <span>文档上传仅限于支持txt格式文件</span>
                            </p>
                            <p>
                                <img src="../../assets/imgs/yellow.png" alt="" />
                                <span>txt文档内数据必须为知网链接，如果想要上传多篇文献，以换行符分隔</span>
                            </p>
                            <p>
                                <img src="../../assets/imgs/yellow.png" alt="" />
                                <span>知网链接推荐域名为https://kns.cnki.net/，如果是其他域名，文献信息可能有所缺失</span>
                            </p>
                            <p>
                                <img src="../../assets/imgs/yellow.png" alt="" />
                                <span>每次仅限上传一个txt文档</span>
                            </p>
                        </div>
                    </div>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消 上 传</el-button>
                <el-button type="primary" @click="submitForm()">确 定 上 传</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import $ from "jquery";
import Vue from "vue";
import { Message } from "element-ui";
import {
    confirmUser,
    getUser,
    judge_user,
    user_mes,
    user_article,
    delete_article,
    new_article,
    new_cited,
    article_export,
    article_import,
    auth_task,
    auth_task_post,
    retracker,
    article_detail,
    article_update,
    retracker_get,
    moreview,
} from "@/Api/unquote";
export default {
    data() {
        return {
            Height: 0,
            en_name: "",
            article: {
                user_openid: localStorage.getItem("code"),
                order_by: "cited_count",
                page_num: 10,
                page: 1,
                article_type: "en",
            },
            value: "cited_count",
            options: [
                {
                    value: "cited_count",
                    label: "被引频次降序",
                },
                {
                    value: "publish_date_desc",
                    label: "发表时间降序",
                },
                {
                    value: "publish_date_asc",
                    label: "发表时间升序",
                },
                {
                    value: "new_cited",
                    label: "最新被引",
                },
                // {
                //   value: "",
                //   label: "继承",
                // },
            ],
            tableData: [
                {
                    publication: 0,
                    cit: 0,
                    hNum: 0,
                },
            ],
            years: [],
            article_publication: [],
            article_cit: [],
            name: localStorage.getItem("name"),
            email: localStorage.getItem("email"),
            org_name: localStorage.getItem("org_name"),
            taskList: [],
            currentPage: 1, // 当前页码
            totalCount: null, //总条数
            actionUrl: "https://track.newacademic.net/api/article_import",
            token: localStorage.getItem("token"),
            ruleForm: {
                title: "",
                author: "",
                task_type: null,
                file: "",
            },
            rules: {
                // title: [{ required: true, message: "请输入文章标题", trigger: "blur" }],
                // author: [
                //   { required: true, message: "请输入文章作者", trigger: "blur" },
                // ],
                // task_type: [
                //   { required: true, message: "请选择检测类型", trigger: "change" },
                // ],
                file: [
                    { required: true, message: "请选择文件上传", trigger: "change" },
                ],
            },
            fileList: [],
            dialogFormVisible: false,
            chosenList: [],
        };
    },
    mounted() {
        //动态设置内容高度 让footer始终居底   header+footer的高度是190
        // console.log(this.name)
        this.Height = document.documentElement.clientHeight - 140; //监听浏览器窗口变化
        window.onresize = () => {
            this.Height = document.documentElement.clientHeight - 140;
        };
    },
    created() {
        this.getData();
        this.getList();
    },
    methods: {
        getData() {
            let register_data = {
                ch_user_name: localStorage.getItem("name"),
                en_user_name: localStorage.getItem("en_name"),
                institution_name: localStorage.getItem("org_name"),
                user_openid: localStorage.getItem("code"),
                article_type: "en",
                type: 1,
            };
            user_mes(register_data)
                .then((res) => {
                    // console.log(res);
                    this.tableData[0].publication = res.data.all_article_count;
                    this.tableData[0].cit = res.data.article_cited_count;
                    this.tableData[0].hNum = res.data.H_num;
                    this.years = [];
                    this.article_publication = [];
                    this.article_cit = [];

                    for (var i in res.data.year_dict) {
                        // console.log(i, ":", res.data.year_dict[i]);
                        this.years.push(i);
                        this.article_publication.push(res.data.year_dict[i].article_num);
                        this.article_cit.push(res.data.year_dict[i].cited_num);
                    }
                    this.myEcharts();
                    // localStorage.setItem("organ_info", res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getList() {
            user_article(this.article)
                .then((res) => {
                    // console.log(res);
                    this.taskList = res.data.data;
                    this.taskList.forEach((item, index) => {
                        Vue.set(item, "or", false);
                        let id_message = {
                            ids: item.article_id,
                            type: "en",
                        };
                        moreview(id_message)
                            .then((res) => {
                                // console.log(res);
                                Vue.set(item, "doi", res.data.data.doi);
                                Vue.set(item, "issn", res.data.data.issn);
                                Vue.set(item, "sub", res.data.data.sub);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
                    this.totalCount = res.data.all_count;
                    // console.log(this.taskList);
                    // localStorage.setItem("organ_info", res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        myEcharts() {
            var myChart = this.$echarts.init(window.document.getElementById("main"));
            //配置图表
            var option = {
                title: {
                    //   text: "近8年内发文量与被引频次对比统计",
                    textStyle: {
                        fontSize: 12, //字体大小
                    },
                },
                tooltip: {},
                legend: {
                    orient: "vertical",
                    x: "right", //可设定图例在左、右、居中
                    //   y: "center", //可设定图例在上、下、居中
                    padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]

                    data: ["发文量", "施引文献"],
                },
                xAxis: {
                    data: this.years,
                },
                yAxis: {},
                series: [
                    {
                        name: "发文量",
                        type: "bar",

                        itemStyle: {
                            normal: {
                                //   color: "#f99362", //改变折线点的颜色
                                lineStyle: {
                                    // color: "#f99362", //改变折线颜色
                                },
                            },
                        },
                        data: this.article_publication,
                        //   showBackground: true,

                        //   barMaxWidth: 30, // 最大宽度
                        label: {
                            //设置柱状图上显示  对应值
                            // show: true,
                            // position: "top",
                            // color: "#606266",
                        },
                    },
                    {
                        name: "施引文献",
                        type: "bar",

                        itemStyle: {
                            normal: {
                                color: "#a899e4", //改变折线点的颜色
                                lineStyle: {
                                    // color: "#f99362", //改变折线颜色
                                },
                            },
                        },
                        data: this.article_cit,
                        //   showBackground: true,

                        //   barMaxWidth: 30, // 最大宽度
                        label: {
                            //设置柱状图上显示  对应值
                            // show: true,
                            // position: "top",
                            // color: "#606266",
                        },
                    },
                ],
            };
            myChart.setOption(option);
        },
        choose(i) {
            this.taskList[i].or = true;
        },
        chooseList() {
            this.chosenList = [];
            this.taskList.forEach((item) => {
                if (item.or == true) {
                    this.chosenList.push(item);
                }
            });
        },
        // 删除
        deletes() {
            this.chooseList();
            // console.log(this.chosenList);
            if (this.chosenList.length > 0) {
                let ids = [];
                this.chosenList.forEach((item) => {
                    ids.push(item.article_id);
                });
                // console.log(ids);
                let user_ids = {
                    ids: JSON.stringify(ids),
                    user_openid: localStorage.getItem("code"),
                    article_type: "en",
                };
                delete_article(user_ids)
                    .then((res) => {
                        // console.log(res);
                        if (res.data.code == 200) {
                            this.$message({
                                message: res.data.message,
                                type: "success",
                            });
                            this.getList();
                        }

                        // localStorage.setItem("organ_info", res.data.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.$message({
                    message: "请勾选删除内容！！",
                    type: "warning",
                });
            }
        },
        // 下载
        download() {
            this.chooseList();
            // console.log(this.chosenList);
            if (this.chosenList.length > 0) {
                let ids = [];
                this.chosenList.forEach((item) => {
                    ids.push(item.article_id);
                });

                // console.log(JSON.stringify(ids));
                let user_ids = {
                    ids: JSON.stringify(ids),
                    user_openid: localStorage.getItem("code"),
                    article_type: "en",
                };
                article_export(user_ids)
                    .then((res) => {
                        // console.log(res);
                        let type = res.headers["content-type"];
                        // 获取文件名
                        let fileName = res.headers["content-disposition"];
                        // console.log(fileName);
                        let regFileNames = "";
                        if (fileName !== undefined) {
                            regFileNames = fileName.match(/=(.*)$/)[1];
                        }

                        let url = window.URL.createObjectURL(
                            new Blob([res.data], { type: type })
                        );
                        let a = document.createElement("a");
                        a.style.display = "none";
                        a.href = url;
                        a.setAttribute("download", regFileNames);
                        document.body.appendChild(a);
                        a.click();
                        url = window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                        this.exportFileloading = false;
                        this.exportFileDialog = false;
                        this.$message({
                            message: "导出成功",
                            type: "success",
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.$message({
                    message: "请勾选下载内容！！",
                    type: "warning",
                });
            }
        },
        // 分页跳转
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.article.page = val;
            this.getList();
            // this.currentPage = val;
            // window.scrollTo(0, 0);
            // this.loadNode = true;
            // this.loading_box = true;
            // this.caseList();
        },
        // 每页显示条数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            // this.pageSize = val;
            // this.loadNode = true;
            // this.loading_box = true;
            // this.caseList();
        },
        // 打开上传
        uploadOpen() {
            this.dialogFormVisible = true;
        },
        handleRemove(file, fileList) {
            // console.log(file, fileList);
        },
        handlePreview(file) {
            // console.log(file);
        },

        // 上传前处理
        beforeUpload(file) {
            //   console.log("aa");
            // let fileSize = file.size;
            // const FIVE_M = 5 * 1024 * 1024;
            // //大于5M，不允许上传
            // if (fileSize > FIVE_M) {
            //   this.$message.error("最大上传5M");
            //   return false;
            // }
            //   判断文件类型，必须是xlsx格式
            //   let fileName = file.name;
            //   let reg = /^.+(\.txt)$/;
            //   if (!reg.test(fileName)) {
            //     this.$message.error("只能上传txt文件!");
            //     return false;
            //   }
            //   return true;
        },
        on_change(file, fileList) {
            // console.log(file, fileList);
            let fileName = file.name;
            let reg = /^.+(\.txt)$/;
            let reg01 = /^.+(\.xls)$/;
            if (!reg.test(fileName) && !reg01.test(fileName)) {
                this.$refs.upload.clearFiles();
                this.$message.error("只能上传txt,xls文件!");
            } else {
                this.ruleForm.file = file;
                this.fileList = fileList;
            }
            //   return true;
        },

        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
                } 个文件`
            );
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        reloadProof() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
        // 确认上传
        submitForm() {
            if (this.fileList.length > 0) {
                var formData = new FormData();
                formData.append("my_file", this.fileList[0].raw);
            } else {
                var formData = new FormData($("#uploadForm")[0]);
            }
            formData.append("article_type", "en");
            formData.append("user_openid", localStorage.getItem("code"));
            let that = this;
            $.ajax({
                url: "https://track.newacademic.net/api/article_import",
                type: "POST",
                cache: false,
                data: formData,
                processData: false,
                contentType: false,
                headers: {
                    // "Content-Type": "application/x-www-form-urlencoded",
                    token: localStorage.getItem("token"),
                    // Token:"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NDE5NzQ4OTAsImlhdCI6MTY0MTM3MDA4MCwiZGF0YSI6eyJpZCI6MTY0LCJlbWFpbCI6IjEwMTM3MTU0NzNAcXEuY29tIiwicGhvbmUiOiIxODR4eHh4MDYwNyIsIm5hbWUiOiJcdTVmOTBcdTU2MDlcdTY1NGYiLCJmYWN1bHR5IjoiXHU1ZjAwXHU1M2QxIiwic3RhdHVzIjowfX0.629NEWBqMr2-unocUDSScxUQAhr0G3HgitzdnM4hw7E",
                },
            })
                .done(function (res) {
                    // console.log(res);
                    //   return;

                    if (res.code == 200) {
                        Message({
                            message: res.message + "!请在任务列表查看",
                            type: "success",
                        });
                        that.dialogFormVisible = false;
                        that.getList();
                    } else {
                        Message({
                            message: res.message,
                            type: "warning",
                        });
                    }

                    // this.returns = false;
                })
                .fail(function (res) {
                    Message.error("提交失败！！请稍后再试！");
                    // this.returns = false;
                });

            return;
            console.log(this.fileList[0].raw);
            //   return
            //   if (this.fileList.length > 0) {
            var formData = new FormData();
            formData.append("my_file", this.fileList[0].raw);
            //   } else {
            //     var formData = new FormData($("#uploadForm")[0]);
            //   }
            console.log(formData);
            formData.append("article_type", "en");
            formData.append("user_openid", localStorage.getItem("code"));
            let files = {
                article_type: "en",
                user_openid: localStorage.getItem("code"),
                my_file: this.fileList[0],
            };

            //   formData.append("task_type", 0);

            article_import(files)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // 排序
        selectSort() {
            this.article.order_by = this.value;
            this.getList();
        },
        // 前往知网
        detail(row) {
            // console.log(row);
            let detail_ids = {
                article_id: row.article_id,
                article_type: "en",
            };
            article_detail(detail_ids)
                .then((res) => {
                    window.open(res.data.data.hownet_url, "_blank");
                })
                .catch((err) => {
                    console.log(err);
                });
            //
        },
        // 已读新增频次
        reads(row) {
            let read_ids = {
                user_openid: localStorage.getItem("code"),
                article_id: row.article_id,
                article_type: "en",
            };
            new_cited(read_ids)
                .then((res) => {
                    this.getList();
                })
                .catch((err) => {
                    console.log(err);
                });
            //
        },
    },
};
</script>

<style lang="less" scoped>
.citation_box {
    min-width: 1360px;
    display: flex;
    color: #000000;

    .box {
        width: 100%;

        .box_part {
            width: 100%;

            .box_top {
                width: 100%;
                max-width: 1500px;
                min-width: 1070px;
                margin: auto;

                .top_left {

                    h2 {
                        font-size: 18px;
                        line-height: 50px;
                    }

                    h3 {
                        color: #b6bbd1;
                        margin-bottom: 20px;
                    }

                    .left_data {
                        padding: 20px 0;
                        padding-top: 0;
                        display: flex;
                        justify-content: space-between;

                        .data_box {
                            padding: 10px 0;

                            p {
                                font-size: 22px;
                                text-align: center;
                                line-height: 50px;
                            }

                            .data_number {
                                color: #7e67db;
                            }
                        }

                        .tables {
                            background: white;
                            padding: 30px;
                            border-radius: 16px;
                            max-height: 350px;
                            p {
                                line-height: 30px;

                                img {
                                    width: 30px;
                                }

                                span {
                                    position: relative;
                                    top: -15px;
                                    left: 10px;
                                }
                            }

                            .citation {
                                display: flex;
                                justify-content: space-between;

                                div {
                                    padding: 20px;
                                    border-radius: 16px;

                                    img {
                                        width: 50px;
                                    }

                                    p {
                                        line-height: 30px;
                                    }

                                    .type {}

                                    .number {
                                        font-size: 23px;
                                        font-weight: 700;
                                        margin-left: 5px;
                                    }

                                    .addText {
                                        color: #3f78ec;
                                    }
                                }
                            }

                        }
                    }
                }
            }

            .box_list {
                width: 100%;
                padding: 20px 0;
                padding-bottom: 0;
                max-width: 1500px;
                min-width: 1070px;
                margin: auto;

                h2 {
                    font-size: 18px;
                    line-height: 50px;
                }

                h3 {
                    color: #b6bbd1;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

/deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
    background-color: #fafafa !important;
}

// color: #7e67db;
.lists {
    .row {
        width: 70%;
        margin: auto;

        p {
            line-height: 30px;
        }

        .title {
            font-size: 18px;
            font-weight: 600;
        }

        .title:hover {
            color: #7e67db;
            cursor: pointer;
        }

        h3 {
            line-height: 30px;
            font-size: 16px;
            color: #7e67db;

            .title_h {
                display: inline-block;
                width: 90px;
            }

            .number {
                display: inline-block;
                color: #ada2a2;
                font-weight: 100;
                font-size: 13px;
                padding-right: 20px;
            }
        }

        .change {
            display: flex;
            color: #7e67db;
            font-size: 16px;

            .change_prt {
                width: 50%;
            }
        }
    }
}

.pag {
    margin: 10px 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.operate {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.note_fu {
    display: flex;
    justify-content: center;
    margin: 20px 0 0 0;

    .note_fu01 {
        width: 100%;
    }
}

/deep/ .el-divider {
    margin: 0 !important;
}

.note {
    padding: 10px 10px;
    border-left: 1px #dcdfe6 solid;
    border-right: 1px #dcdfe6 solid;
    border-bottom: 1px #dcdfe6 solid;

    p {
        line-height: 30px;
        text-indent: -1.5em;
        /*em是相对单位，可根据自己实际情况来调节*/
        padding-left: 1.5em;
        /*em是相对单位，可根据自己实际情况来调节*/
        word-wrap: break-word;
        img {
            margin: 0 10px 0 0;
        }
    }
}

/deep/ .el-upload-list {
    margin: 0 20px;
    position: relative;
    top: -50px;
}

.Echarts {
    position: relative;
    top: -50px;
}

.item {
    margin-top: 8px;
    cursor: pointer;
}
</style>